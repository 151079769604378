/*    div {
       background: #f00;
    }
    ul li {
        color: orange;
        font-weight: bold;
    }
 */


div.header-top li {
   list-style: none;
   margin-left:20px;
   margin-right:20px;
}

 div.product {

   margin-bottom:30px;

 }

 div.product img {
    height:200px;
    margin-top:15px;
    margin-bottom:10px;
 }

 div.flex-row-reverse {
    display: flex;
    flex-direction:reverse;
    font-size:2em;
 }

 div.flex-row-reverse div {
   margin-left:10px;
   margin-right:10px;
 } 

 .flex-container {
   display: flex;
   /*flex-direction: row;*/
   flex-direction:row-reverse;
 }

 .flex-container div {
   margin-left:10px;
   margin-right:10px;
 } 


 .product a {
   text-decoration:none;
   color:var(--bs-body-color);
 }
 

/*
      <div>
        <div className="splogo"><span className="shop">Shop</span><span className="pilot">Pilot</span></div>
        <div className="spdescr">&nbsp;&nbsp;e-Business Solutions</div>
      </div>

*/

.splogo {
   font:bolder;
   font-size: xx-large;
   font-stretch: ultra-condensed;
   font-family:"Arial Black", Verdana, Geneva, Tahoma, sans-serif
}

.splogo .shop {

   color:var(--bs-black);

}

.splogo .pilot {

   color:var(--bs-blue);

   
}
.spdescr {
   color:var(--bs-blue);
   font-family: Verdana, Geneva, Tahoma, sans-serif;
   font-size:small;
   margin-top:-10px;
   font-stretch:expand;
} 

/* animation */
@keyframes placecart { 
    0%    {    transform: scale(1) translateY(0px);    opacity: 0;    box-shadow: 0 0 0 rgba(241, 241, 241, 0);  }
    1%   {    transform: scale(0.96) translateY(10px);    opacity: 0;    box-shadow: 0 0 0 rgba(241, 241, 241, 0);  }
    100% {    transform: scale(1) translateY(0px);    opacity: 1;    box-shadow: 0 0 500px rgba(241, 241, 241, 0);  }
}

.popup-content {
   animation-name: placecart; 
   animation-duration: 0.5s;
   animation-timing-function: cubic-bezier(0.38, 0.1, 0.36, 0.9);
}

.popup-content {  margin: auto;  background: rgb(255, 255, 255);  width: auto;  padding: 5px;}

.popup-content .modalx {padding:20px;}
.popup-content .modalx .buttons {display:flex;}
.popup-content .modalx .buttons .left {display:flex;}
.popup-content .modalx .buttons .right {display:flex;}
.popup-content .modalx h3 {
   color: blue;
   font-size:xx-large;
   text-shadow: 0 0 2px grey;
}

.popup-arrow {  color: rgb(255, 255, 255);}[role='tooltip'].popup-content {  width: 200px;  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;}

.popup-overlay {  background: rgba(0, 0, 0, 0.5);}[data-popup='tooltip'].popup-overlay {  background: transparent;}



.warenkorb .header th {
   padding-right:20px;

}
input.count-input {
   width:120px;
   display:inline-block;
}

.linequantity {
   width:100%;
   text-align:center;
}


.user_login_logout .dropdown-menu {
   min-width: 250px;
}

.error-msg {
   color:red;
   font-weight:bold;
}

div.checkout1 {
   background:#eeeeee;
 }

 div.borderbox {
   border: solid 1px #a0a0a0;
   padding:15px;
   margin-bottom:15px;
 }

 div.checkoutnavi {
   padding:20px;
   background:#ddd;
   margin-top:10px;
   margin-bottom:10px;
   border-radius: 25px;

 }

 div.paypal-overlay {
   z-index:99; background:#222; opacity:0.5;position:relative;
 }
 div.paypal-overlay iframe{
   pointer-events: none;
 }

 div#navbarsExample04 {
   font-size:18px;
   font-weight:600; 
 }
 
 .dropdown-toggle:hover .dropdown-menu {
   display: block;
   margin-top: 0; /* remove the gap so it doesn't close */
}

.search_input {
   width:80%;
   margin:20px;
   padding:8px;
}
.searchframe {
  display:flex;
  align-items:center;
  padding-top:15px;
}

.userandcart {
   font-size: 2em;
   display:flex;
   align-items:center;
 }

#xsearch-input-form {
   width:90%;
}


.boxheight {
   height:200px;
   overflow:hidden;
}

.margin-buy-box {
   margin-bottom:10px;
}

.breadcrums {
   text-decoration:dotted;
   font-size: medium;
   font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

.img-container {
   width:300px;
   overflow:hidden;
}

.img-container div{
   height:1px;
}
.img-container img:hover {
	transform:scale(1.2);
	transition: all 0.3s linear;
}

div.product img.overlay-img {
   position: relative;
   bottom: 70px;
   left: 5x;
   width: 55px; /* Breite des kleineren Bildes */
   height: auto;
}
